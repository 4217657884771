.CUISearchButton
{
    background-color: #003172;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 20px;
    margin-left: 5px;
    height: 40px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    font-family: 'Inter', Arial;
}

.CUISearchButton:hover
{
    background-color: #e0e0e0;
    border-color: #d5d5d5;
    color: #616161;
}