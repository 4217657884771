.list-group-flush {
    font-size: 14px;
    font-weight: 500;
    padding-left: 0;
    transition: all 0.3s ease-in-out;
  }
  
  .list-group-flush .list-group-item {
    padding: 15px 20px;
    border: none;
    color: #003172;
    transition: all 0.3s ease-in-out;
  }
  
  .list-group-flush .list-group-item:hover {
    background-color: #f8f9fa;
    cursor: pointer;
  }
  
  .list-group-flush .list-group-item i {
    color: #003172;
    font-size: 16px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
  }
  
  .invoicecard-header {
    background-color: white;
    color: #003172;
    font-weight: 700;
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #e9ecef;
    transition: all 0.3s ease-in-out;
  }

  .carddelete:hover {
    color: red;
  }

  .carddelete {
    color: #003172;
  }
  
  .cardview:hover {
    color: #003172;
  }

  .cardview {
    color: gray;
  }

  .cardedit:hover {
    color: #003172;
  }

  .cardedit {
    color: gray;
  }
  
  .invoicecard-header:hover {
    background-color: #e9ecef;
    cursor: pointer;
  }

  .invoicecard-header:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 4px;
    background-color: #003172;
    transform: scaleY(0);
    transform-origin: left;
    transition: all 0.3s ease;
  }
  
  .invoicecard:hover .invoicecard-header:before {
    transform: scaleY(1);
  }
  
  .invoicecard {
    box-shadow: -5px 6px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    border: none;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .invoicecard:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .invoicecard-img-top {
    border-radius: 50% 50% 0 50%;
    transition: all 0.3s ease-in-out;
  }
  
  .invoicecard-img-top:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  