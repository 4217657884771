@media screen and (max-width: 575px) {

    .deletecontent
    {
        display: none;
    }

    #logintext
    {
        display: none;
    }

    .loginform
    {
        margin:80px 0px 150px 0px;
    }

    #logintri1 {
        width: 230px;
        height: 230px;
        margin-top: 300px;
        margin-left: 20px;
    }

    #logintri2 {
        width: 230px;
        height: 230px;
        margin-top: 20px;
        margin-left: 60px;
    }

}

#logintri1 {
    width: 100vh;
    height: 100vh;
    background: linear-gradient(#22a1e2, #003172);
    background-size: cover;
    position: absolute;
    width: 430px;
    height: 430px;
    margin-top: 300px;
    margin-left: -1050px;
    transform: translate3d(0, 0, 0);
    transform: rotate(45deg);
    filter: hue-rotate(0deg) contrast(140%);
    opacity: 0.5;
}

#logintri2 {
    width: 100vh;
    height: 100vh;
    background: linear-gradient(#003172, #22a1e2);
    background-size: cover;
    position: absolute;
    width: 430px;
    height: 430px;
    margin-top: 20px;
    margin-left: 510px;
    transform: translate3d(0, 0, 0);
    transform: rotate(45deg);
    filter: hue-rotate(0deg) contrast(140%);
    opacity: 0.5;
}

.loginform
{
    margin:150px 0px 150px 0px;
}

