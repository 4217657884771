.menusidebartopcontainer
{
    display: flex;
}

@media only screen and (max-width: 768px) {
    .menusidebartopcontainer {
      display: block;
    }
  }
