.navigationlink
{
    color: black;
    font-weight: 800;
    margin: 20px 20px 15px 20px;
    text-decoration: 0;
}

.navigation
{
    z-index: 200;
    box-shadow: 0px 0px 0px grey;
    height: 100px;
    width: 100%;
}