.dealcard {
    height: 270px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: -5px 4px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .dealcard:hover {
    transform: translateY(-6px);
    box-shadow: -5px 6px 20px rgba(0, 0, 0, 0.2);
  }
  
  .dealcard-header {
    background: white;
    padding: 16px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid gray;
    text-align: center;
    position: relative;
  }
  
  .dealcard-header:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 4px;
    background-color: #003172;
    transform: scaleY(0);
    transform-origin: left;
    transition: all 0.3s ease;
  }
  
  .dealcard:hover .dealcard-header:before {
    transform: scaleY(1);
  }
  
  .dealcard-header h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: #003172;
  }
  
  .dealcard-body {
    padding: 16px;
  }
  
  .dealcard-body p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #495057;
    line-height: 1.5;
  }
  
  .dealcard-body p:not(:last-child) {
    margin-bottom: 8px;
  }
  
  .dealcard-footer {
    background-color: #f9fafb;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    /*display: flex;*/
    justify-content: space-between;
    align-items: center;
  }
  
  .dealcard-footer p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #003172;
  }
  
  .dealcard-footer span {
    font-size: 16px;
    font-weight: 500;
    color: #212529;
  }
  