.customersbutton 
{
    text-align: left;
    padding-left: 50px;
    border-radius: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 90%; 
    height: 100%;
    background: white; 
    border: none; 
    color: #003172;
}

.customerscol
{
    font-size: 30px;
    font-family: 'Inter', arial;
    color: #003172;
    padding: 0px;
    height: 240px;
    text-align: center;
    margin-bottom: 20px;
}