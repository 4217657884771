.sectionbutton 
{
    text-align: left;
    padding-left: 50px;
    border-radius: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 90%; 
    height: 100%;
    background: white; 
    border: none; 
    color: #003172;
}

.sectioncol
{
    font-size: 30px;
    font-family: 'Inter', arial;
    color: #003172;
    padding: 0px;
    height: 120px;
    text-align: center;
    margin-bottom: 20px;
}

.crmsections
{
    overflow-x: auto;
    padding-bottom: 10px;
}

.crmsection
{
    margin-right: 10px;
    background: #003172;
    color: white;
    border: none;
    border-radius: 40px;
    padding: 10px 20px 10px 20px;
}

.crmsection:hover
{
    color: black;
    background-color: lightblue;
}

/* width */
::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightblue;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #003172;
  }