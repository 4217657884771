.itemscontainer 
{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
    .disappear {
      display: none;
    }

    .itemscontainer {
      padding: 10px;
    }
}