.profile-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    max-width: 400px;
    margin: 0 auto;
    background-color: #f9f9f9;
}

.profile-content {
    text-align: center;
}

.profile-header {
    margin-bottom: 10px;
}

.profile-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.label {
    font-weight: bold;
}

.value {
    font-style: italic;
}
