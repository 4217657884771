.CreateCard
{
    color: grey;
    border-color: grey;
    border-style: dashed;
    border-width: 2px;
    margin: 10px 10px 10px 10px;
    paddingBottom: 50px;
    text-align: center;
}

.CreateCard:hover
{
    border-color: #22a1e2;
    color: #22a1e2;
}

.CreateBody
{
    padding: 47px 0px 47px 0px;
}

.Subtitle
{
    fontSize: 12px;
    textAlign: center;
    opacity: 0;
}