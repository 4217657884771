.headertext
{
    font-family: 'Inter', Arial;
    color: #003172;
}

.headercontainer
{
    text-align: center;
    margin: 50px 0px;
}

.maintext
{
    font-family: 'Inter', Arial;
    color: #003172;
}

.maintextcontainer
{
    text-align: center;
}