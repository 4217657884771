.formsection
{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    padding: 50px 40px;
    margin: 40px 0px;
}

.categoryselect
{
    width: 100%;
}

.taxselect
{
    width: 100%;
}