.formsection
{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    padding: 50px 40px;
    margin: 40px 0px;
}

@media only screen and (max-width: 768px) {
    .formsection
    {
        padding: 20px 20px;
    }
}