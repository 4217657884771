.content 
{
  min-height: 100vh;
  text-align: center;
  margin-top: 100px;
}

.text-content
{
  margin-top: 500px;
}

.functions3rd
{
  width: 50%;
}

.morebutton
{
  padding: 15px 30px 15px 30px;
  border: 1px solid #003172;
  color: #003172;
  background: none;
  font-family: 'Inter', arial;
}

.morebutton:hover
{
  background: #003172;
  color: white;
}


#noitemstri1 {
  background: linear-gradient( #22a1e2, #003172);
  background-size: cover;
  position: absolute;
  width: 120px;
  height: 120px;
  left: 50%;
  margin-left: -400px;
  top: 7.4%;
  transform: translate3d(0, 0, 0);
  transform: rotate(45deg);
  filter: hue-rotate(0deg) contrast(140%);
  opacity: 0.5;
  Z-index: 0;
}

@media screen and (max-width: 575px)
{
  .functions3rd
  {
    width: 100%;
  }

  .hometoprow
  {
    margin-top: 0px;
  }

  .toph1
  {
    margin-top: 5vh;
    font-size: 30px;
  }

  .topp
  {
    font-size: 18px;
  }

  .bgimage {
    margin-left: 0px;
    width: 100%;
  }
}

.bgimage {
  margin-left: 220px;
  width: 50rem;
}

.Header
{
  margin-top: 100px;
  min-height: 100vh;
  width: 100vw;
}

.toph1
{
  margin-top: 130px;
  font-size: 50px;
}

.topp
{
  font-size: 25px;
}



.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .wave-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-bottom: 50px;
  }
  
  .wave {
    background-image: linear-gradient(to bottom right, #fff, #ddd);
    height: 100px;
    width: 100%;
    border-radius: 0 0 60% 60% / 0 0 40% 40%;
    margin-bottom: 50px;
  }
  
  .section-content {
    text-align: center;
  }

#homebutton1:hover
{
  background-color: white;
  color: #003172;
}

#homebutton1
{
  background-color: #003172;
  color: white;
  border: 2px solid #003172;
}

#homebutton2:hover
{
  background-color: #003172;
  color: white;
}

#homebutton2
{
  background-color: white;
  color: #003172;
  border: 2px solid #003172;
}




.formbold-mb-5 {
  margin-bottom: 20px;
}
.formbold-pt-3 {
  padding-top: 12px;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  background: white;
}
.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.formbold-form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: #003172;
  color: white;
  width: 100%;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 5px 8px lightblue;
}

.formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
@media (min-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
}
