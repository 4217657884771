@import "~react-vis/dist/style";

.selectfarasageata {
  appearance: none;
}

.defaultpadding
{
  padding: 20px;
}

.defaultshadow
{
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.defaultdistancing
{
  margin: 30px 0px 30px 0px;
}

input[type="file"] {
  display: none;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}


body
{
  overflow-x: hidden;
}

@media screen and (max-width: 575px) {

  .deletecontent
  {
    display: none;
  }

  .mainside
  {
    position: absolute;
    left: 0px;
  }

}

.forms:focus
{
  box-shadow: none;
}

.center
{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-toggle::after {
  display: none !important;
  border: none; 
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  font-weight: bold;
  color: #003172;
  border-bottom-style: solid;
  border-color: #003172;
}

.invoicestabs .nav-link.active {
  background: #003172;
  color: white;
  border-radius: 10px 10px 10px 10px
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  color: grey;
  font-weight: bold;
  border-bottom-style: solid;
  border-color: lightgrey;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.nav-tabs .nav-link:hover{
  color: grey;
}