.fab-container {
    display: flex;
    // Display actions from bottom to top
    flex-direction: column-reverse;
    list-style: none;
    margin: 0;
    padding: 0;
  
    // Display button to the bottom right
    position: fixed;
    z-index: 1000;
    right: 1.5em;
    bottom: 2em;
  
    // Set max height to only trigger mouse enter
    // when user hover over first button
    max-height: 52px;
  
    &.open {
      // If open, container should fill the whole height
      max-height: max-content;
    }
  
    li {
      border-radius: 50%;
      box-shadow: 0 3px 6px lightgrey;
      display: grid;
      place-items: center;
      margin: 8px 0;
      font-size: 28px;
      padding: 20px;
      cursor: pointer;
      position: relative;
    }
  }

  .fab-button {
    background-color: #003172;
    padding: 20px;
  
    svg {
      fill: white;
    }
  }

  .fab-action {
    // In React - We're rendering actions all the time
    // that is why we're hiding them 
    // by setting scale and opacity to 0  
    transform: translateY(50px) scale(0);
    opacity: 0;
    transition: transform 300ms, opacity 300ms;
  
    &.open {
      // When user hovers in, we display the actions
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  
    &:hover {
      .tooltip {
        transform: translateX(-100%) scale(1);
        opacity: 1;
      }
    }
  
    .tooltip {
      padding: 4px 6px;
      font-size: 12px;
      position: absolute;
      left: -12px;
      transform: translateX(-75%);
      background-color: #353b48;
      border-radius: 4px;
      color: white;
      opacity: 0;
      transition: transform 300ms, opacity 300ms;
      user-select: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .fab-container {
      display: none;
    }
  }