.list-group-flush {
    font-size: 14px;
    font-weight: 500;
    padding-left: 0;
    transition: all 0.3s ease-in-out;
  }
  
  .list-group-flush .list-group-item {
    padding: 15px 20px;
    border: none;
    color: #333;
    transition: all 0.3s ease-in-out;
  }
  
  .list-group-flush .list-group-item:hover {
    background-color: #f8f9fa;
    cursor: pointer;
  }
  
  .list-group-flush .list-group-item i {
    color: #777;
    font-size: 16px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
  }

  .carddelete:hover {
    color: red;
  }

  .carddelete {
    color: gray;
  }
  
  .cardview:hover {
    color: #003172;
  }

  .cardview {
    color: gray;
  }

  .cardedit:hover {
    color: #003172;
  }

  .cardedit {
    color: gray;
  }

  .clientcard-header {
    background-color: white;
    color: #003172;
    font-weight: 700;
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #e9ecef;
    transition: all 0.3s ease-in-out;
}

.clientcard-header:hover {
background-color: #e9ecef;
cursor: pointer;
}

.clientcard {
  box-shadow: -5px 6px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  border: none;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.clientcard:hover {
transform: translateY(-10px);
box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
cursor: pointer;
}

.clientcard-img-top {
border-radius: 10%;
transition: all 0.3s ease-in-out;
}

.clientcard-img-top:hover {
transform: scale(1.1);
cursor: pointer;
}